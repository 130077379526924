import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../../components/Loading";
import {
  faArrowLeft,
  faPlus,
  faSave,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import {
  METADATA_CREACION_PRODUCTO,
  buildFormDataCreateProduct,
  METADATA_DETALLE_ACTIVO,
  buildFormDataCreateProductParticulars,
} from "./constants";
import { CardForm } from "../../components/CardForm";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalAssetCreateProduct } from "../../components/ModalAssetCreateProduct";
import { toast } from "react-toastify";
import * as S from "./styles";
import { useDataContext } from "../../context/DataState";
import { portfolios } from "../../constants";
import usePermition from "../../constants/usePermition";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export const CrearProducto = () => {
  const [valueFilter] = useState("");
  const [language, setLanguage] = useState("ES");
  const [data, setData] = useState({});
  const [dataActivos, setDataActivos] = useState([]);
  const [idActivos, setIdActivos] = useState([]);
  const [dataSelectedActivo, setDataSelectedActivo] = useState({});
  const [activoActual, setActivoActual] = useState("0");
  const [validated, setValidated] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalAnadirActivos, setModalAnadirActivos] = useState(false);
  const [prinexIDs, setPrinexIds] = useState([]);
  const [metadataAsset, setMDAsset] = useState(METADATA_DETALLE_ACTIVO);
  const [MDProduct, setMDProduct] = useState([]);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    if (PERMISOS_POR_ROL.DetalleProducto.Guardar) {
      document.title = "EC-Light-Crear Producto";

      let isMounted = true;
      let listsAux = {};
      if (isMounted && location.state.activos) {
        if (appLists !== null) {
          listsAux = appLists;

          let data = {
            iDs: location.state.activos,
          };

          showLoader();
          API.obtenerActivosProducto(data)
            .then((res) => {
              let pIds = [];
              res.data.forEach((element) => {
                let PI = {};
                PI.prinexId = element.prinexId;
                PI.id = element.id;
                pIds.push(PI);
                if (element.portfolioId !== portfolios.name.anticipa) {
                  let tmpMDA2 = metadataAsset;
                  tmpMDA2 = tmpMDA2.filter(
                    (e) => e.label !== "Destinos publicación Anticipa"
                  );
                  setMDAsset(tmpMDA2);
                }
              });
              setPrinexIds(pIds);
              setIdActivos(res.data.map((s) => s.id));
              let mdProd = METADATA_CREACION_PRODUCTO(
                user.currentPortfolioID === portfolios.name.particulares ||
                  user.currentPortfolioID === portfolios.name.terceros
              );
              if (res.data.some((s) => s.businessLine === 1)) {
                setMDProduct(mdProd);
              } else {
                setMDProduct(mdProd.filter((e) => e.id !== "floor"));
              }
              setDataSelectedActivo(
                res.data.find((s) => s.id === Number(location.state.activos[0]))
              );
              setDataActivos(res.data);
              setActivoActual(Number(location.state.activos[0]));

              //Open panels
              let accs = document.getElementsByClassName("accordion-button");
              for (var i = 0, len = accs.length; i < len; i++) {
                accs[i].click();
              }
              showLoader();
              API.obtenerUsuariosList()
                .then((resUsuarios) => {
                  listsAux.users = resUsuarios.data;
                  listsAux.assets = res.data.map((s) => ({
                    id: s.id,
                    name: s.dossierCode + " (" + s.prinexId + ")",
                  }));
                  showLoader();
                  API.obtenerDelegaciones()
                    .then((resDelegaciones) => {
                      listsAux.delegationList = resDelegaciones.data;
                      dispatch({
                        type: "UPDATE_APP_LIST",
                        payload: listsAux,
                      });
                    })
                    .catch((error) => {
                      API.DEVELOP && console.log(error);
                    })
                    .finally(() => hideLoader());
                })
                .catch((error) => {
                  API.DEVELOP && console.log(error);
                })
                .finally(() => hideLoader());
            })
            .catch((error) => {
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        }
        return () => (isMounted = false);
      }
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader, hideLoader, location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasDataChanged) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasDataChanged]);

  const defaultSortActivos = [
    {
      id: "id",
      desc: false,
    },
  ];

  /**
   * Verificar si hay cambios antes de salir en la vista de crear
   */
  const goBack = () => {
    if (!hasDataChanged) {
      navigate(-1);
    } else {
      if (window.confirm("Existen cambios sin guardar. ¿Desea salir?")) {
        navigate(-1);
      }
    }
  };

  const handleDataChange = (values) => {
    setData(values);
    if (!!values.defaultAssetId) {
      setHasDataChanged(true);
    } else {
      setHasDataChanged(false);
    }
  };

  const handleSubmitCreateProduct = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    const currentCartera = user.currentPortfolioID;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (
        user.currentPortfolioID !== portfolios.name.particulares &&
        user.currentPortfolioID !== portfolios.name.terceros
      ) {
        let formData = buildFormDataCreateProduct(data);
        formData.assetIDs = idActivos;
        showLoader();
        API.crearProducto(formData)
          .then((res) => {
            toast.success("Producto creado correctamente");
            navigate(
              "/product-detail/" + res.data.id + "/" + res.data.defaultAssetId
            );
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      } else {
        let formData = buildFormDataCreateProductParticulars(data);
        formData.assetIDs = idActivos;
        showLoader();
        const serviceForCrearProducto =
          currentCartera === "CAR-PARTI"
            ? API.crearProductoParticulares
            : API.crearProductoTerceros;
        serviceForCrearProducto(formData)
          .then((result) => {
            toast.success("Producto creado correctamente");
            navigate(
              "/product-detail/" +
                result.data.id +
                "/" +
                result.data.defaultAssetId
            );
          })
          .catch((e) => API.DEVELOP && console.log(e))
          .finally(hideLoader);
      }
    }
  };

  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  const confirmAnadirActivosProducto = (value) => {
    let dataAux = dataActivos.map((s) => s.id).concat(value);
    let data = {
      iDs: dataAux,
    };
    showLoader();
    API.obtenerActivosProducto(data)
      .then((res) => {
        setIdActivos(res.data.map((s) => s.id));
        setDataActivos(res.data);
        let tmpLists = { ...appLists };
        tmpLists.assets = res.data.map((s) => ({
          id: s.id,
          name: "(" + s.id + ") " + s.name,
        }));
        let pIds = [];
        res.data.forEach((element) => {
          let PI = {};
          PI.prinexId = element.prinexId;
          PI.id = element.id;
          pIds.push(PI);
          if (element.portfolioId !== portfolios.name.anticipa) {
            let tmpMDA2 = metadataAsset;
            tmpMDA2 = tmpMDA2.filter(
              (e) => e.label !== "Destinos publicación Anticipa"
            );
            setMDAsset(tmpMDA2);
          }
        });
        setPrinexIds(pIds);
        let mdProd = METADATA_CREACION_PRODUCTO(
          user.currentPortfolioID === portfolios.name.particulares ||
            user.currentPortfolioID === portfolios.name.terceros
        );
        if (res.data.some((s) => s.businessLine === 1)) {
          setMDProduct(mdProd);
        } else {
          setMDProduct(mdProd.filter((e) => e.id !== "floor"));
        }
        dispatch({
          type: "UPDATE_APP_LIST",
          payload: tmpLists,
        });
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
    setModalAnadirActivos(false);
  };

  const deleteActivo = (e, id) => {
    e.stopPropagation();
    let prinexIDsAux = [...prinexIDs];
    if (prinexIDsAux.length > 1) {
      prinexIDsAux = prinexIDsAux.filter((s) => s.id !== id);
      let dataActivosAux = [...dataActivos];
      dataActivosAux = dataActivosAux.filter((s) => s.id !== id);
      let idActivosAux = [...idActivos];
      idActivosAux = idActivosAux.filter((s) => s !== id);
      let tmpLists = { ...appLists };
      tmpLists.assets = tmpLists.assets.filter((s) => s.id !== id);
      let mdProd = METADATA_CREACION_PRODUCTO(
        user.currentPortfolioID === portfolios.name.particulares ||
          user.currentPortfolioID === portfolios.name.terceros
      );
      if (dataActivosAux.some((s) => s.businessLine === 1)) {
        setMDProduct(mdProd);
      } else {
        setMDProduct(mdProd.filter((e) => e.id !== "floor"));
      }
      if (id === activoActual) {
        setActivoActual(prinexIDsAux[0].id);
        let dataAux = { ...data };
        dataAux.defaultAssetId = null;
        setData(dataAux);
      }
      setPrinexIds(prinexIDsAux);
      setDataActivos(dataActivosAux);
      setIdActivos(idActivosAux);
      setDataSelectedActivo(
        dataActivosAux.find((s) => s.id === prinexIDsAux[0].id)
      );
      dispatch({
        type: "UPDATE_APP_LIST",
        payload: tmpLists,
      });
    }
  };

  return (
    <S.DetailPage>
      {loader}
      {modalAnadirActivos && (
        <ModalAssetCreateProduct
          ids={appLists.assets.map((s) => s.id).join(",")}
          message="Seleccionar activos"
          lists={appLists}
          defaultSort={defaultSortActivos}
          hiddenColumns={["id"]}
          labelConfirm="Guardar"
          cancel={() => setModalAnadirActivos(false)}
          confirm={confirmAnadirActivosProducto}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
      )}
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col>
          <S.Title>Nuevo Producto</S.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <Form
                noValidate
                id="createProduct"
                validated={validated}
                onSubmit={handleSubmitCreateProduct}
              >
                <Card>
                  <Card.Header>
                    <div>
                      <Button
                        variant="danger"
                        onClick={goBack}
                        style={{ marginRight: "1rem" }}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                      </Button>
                      <Button
                        type="submit"
                        form="createProduct"
                        variant="success"
                        // disabled={!enabledSave}
                      >
                        <span>
                          <FontAwesomeIcon icon={faSave} size="lg" fixedWidth />
                        </span>
                        Guardar
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {MDProduct.map((field, index) => (
                      <CardForm
                        metadata={field.metadata}
                        label={field.label}
                        collapsable={field.collapsable}
                        filter={valueFilter}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={handleDataChange}
                        key={index}
                      />
                    ))}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
            <Col>
              <Form noValidate id="infoAsset" validated={validated}>
                <Card>
                  <Card.Header>
                    <h4>
                      Activos <S.NumActivos>{dataActivos.length}</S.NumActivos>
                    </h4>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          style={{ marginRight: "1rem" }}
                          variant="success"
                          onClick={() => setModalAnadirActivos(true)}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faPlus}
                              size="lg"
                              fixedWidth
                            />
                          </span>
                          Añadir Activos
                        </Button>
                        <SelectLanguage
                          language={language}
                          onLanguageChange={onLanguageChange}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={3}>
                        <ListGroup>
                          {prinexIDs.map((activo, index) => (
                            <ListGroup.Item
                              key={index}
                              onClick={() => {
                                setDataSelectedActivo(
                                  dataActivos.find(
                                    (s) => s.id === Number(activo.id)
                                  )
                                );
                                setActivoActual(activo.id);
                              }}
                              style={
                                activoActual === activo.id
                                  ? {
                                      color: "#3c8dbc",
                                      cursor: "pointer",
                                      border: "1px solid #ff0e49",
                                      borderRight: "0",
                                      backgroundColor: "#fff",
                                      borderTopRightRadius: "0",
                                      borderBottomRightRadius: "0",
                                      borderTopLeftRadius: "0",
                                      borderBottomLeftRadius: "0",
                                    }
                                  : {
                                      color: "#3c8dbc",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {activo.prinexId}
                              <S.DeleteActivo
                                onClick={(e) => deleteActivo(e, activo.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  size="lg"
                                  fixedWidth
                                />
                              </S.DeleteActivo>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col xs={12} md={9}>
                        {metadataAsset.map((field, i) => {
                          return (
                            <CardForm
                              key={i}
                              metadata={field.metadata}
                              label={field.label}
                              collapsable={field.collapsable}
                              filter={valueFilter}
                              language={language}
                              languageSensitive={field.languageSensitive}
                              lists={appLists}
                              data={dataSelectedActivo}
                              setData={setDataSelectedActivo}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.DetailPage>
  );
};
